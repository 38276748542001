<template>
  <div id="theWholeCard">
    
  <div :id="rental.id" class="carousel carousel-dark slide" data-bs-pause="false">
  <div class="carousel-inner">
    <div  class="carousel-item imagePart" v-for="image in rental.bunnyImageUrls" :key="image" :class="image === rental.bunnyImageUrls[0] ? 'active' : ''">
      <!-- <div class="carousel-indicators">
        <p style="color: white">{{rental.imageUrls.indexOf(image)}} / {{rental.imageUrls.length}}</p>
      </div>   -->
      <router-link :to="rentalDetail" class="imageContainer">
        <img :src="image" class="img-fluid rounded d-block w-100" :alt="rental.id"/>
        <p class="top-right">{{1 + rental.bunnyImageUrls.indexOf(image)}} / {{rental.bunnyImageUrls.length}}</p>
      </router-link>
        
    </div>
  </div>
  <button class="carousel-control-prev" type="button" :data-bs-target="'#' + rental.id" data-bs-slide="prev">
    <span class="carousel-control-prev-icon bclour" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" :data-bs-target="'#' + rental.id" data-bs-slide="next">
    <span class="carousel-control-next-icon bclour" aria-hidden="true"></span>
    <span class="visually-hidden ">Next</span>
  </button>
</div>


    <div class="">
      <p class="card-title">
       <span class="fst-itali">{{ rental.location }}, Kampala</span>
      </p>
      <p class="card-title">
        Owner: <span class="fst-itali">{{ rental.ownerName }}</span>
      </p>
      <p class="card-title">
        Owner Contacts: <span class="fst-itali">{{ rental.ownerPhoneNumber }}</span>
      </p>
      <p class="card-title">
        buildingName: <span class="fst-itali">{{ rental.buildingName }}</span>
      </p>
      <p class="card-title">
        apartmentNumber: <span class="fst-itali">{{ rental.apartmentNumber }}</span>
      </p>
      <p class="card-text">
        <span class="">{{ rental.numberOfBedrooms }} <span v-if="rental.numberOfBedrooms == 1">Bedroom</span><span v-else> Bedrooms</span> <span v-if="rental.sittingRoom">and {{ rental.sittingRoom }} sitting room</span></span>
      </p>
      <p> 
        <strong>{{ rental.price.toLocaleString('UG', { style: 'currency', currency: 'UGX' }) }} UGX</strong> / month
      </p>
      <!-- <p>Contact us on <strong><a href="tel: +256789647271">+256 789647271</a></strong></p> -->
      <i class="fa-brands fa-whatsapp"></i> <a href="https://wa.me/+256751568755/?text=Hello">+256 751568755</a>
      
    </div>
    <div class="approved">
      <p>Approved: <span>{{approvedStatus}}</span></p>
      <button class="btn btn-primary" @click="approveRental">{{approveContent}}</button>
      
    </div>
    <div class="book">
      <p>booked: <span>{{rental.booked}}</span></p>
      <button class="btn btn-primary" @click="bookRental">{{bookContent}}</button>
    </div>
    <div v-if="!updatepropertyHasVideo">
        <form @submit.prevent="addVideoId">
          <div class="form-group">
          <label for="videoId">Add video Id</label>
          <input type="text" class="form-control" id="videoId" v-model.trim="videoId">
        </div>
        <button class="btn btn-primary mt-1" type="submit">submit</button>
        </form>
      </div>
  </div>
</template>

<script>
export default {
  props: ["rental"],
  data() {
    return {
      approvedStat: this.rental.approved,
      bookedStat: this.rental.booked,
      videoId: "",
      propertyHasVideo: this.rental.videoId
    }
  },
  computed: {
    rentalLink() {
      return "/rentals/" + this.rental.id + '/contact';
    },
    rentalDetail() {
      return "/rentals/" + this.rental.id;
    },
    approveContent() {
      if(this.approvedStat) {
        return 'Disapprove Rental'
      } else {
        return 'Approve Rental'
      }
    },
    bookContent() {
      if(this.bookedStat) {
        return 'Unbook rental'
      } else {
        return 'Book rental'
      }
    },
    approvedStatus() {
      return this.rental.approved
    },
    updatepropertyHasVideo() {
      return this.propertyHasVideo
    }

  },
  methods: {
    async approveRental() {
      await this.$store.dispatch('admin/approveRental', {id: this.rental.id, approved: this.rental.approved})
      this.approvedStat = !this.approvedStat
      // this.$router.replace("/rentals");
    },
    async bookRental() {
      // console.log("this is the id of the rental", this.rental.id)
      await this.$store.dispatch('admin/bookRental', {id: this.rental.id, booked: this.rental.booked})
      this.bookedStat = !this.bookedStat
      // this.$router.replace("/rentals");
    },
    async addVideoId() {
      await this.$store.dispatch('admin/addVideoId', {id: this.rental.id, videoId: this.videoId})
      this.videoId = ""
      this.propertyHasVideo = false
    }
  },
};
</script>

<style scoped>
#theWholeCard {
  max-height: 120vh;
  max-width: 25rem;
}
span {
  font-weight: bold;
}

div .imagePart {
  width: 100%;
  height: 20rem;
}
p {
  margin-bottom: .4rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  position: relative;
  text-align: center;
  color: white;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: rgb(65, 59, 59);
  padding-left: .3rem;
  padding-right: .3rem;
  border-radius: .2rem;
}

a {
  text-decoration: none;
}

.bclour {
  background-color: black;
  border-radius: .3rem;
}

.approved {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.book {
  display: flex;
  justify-content: space-between;
  margin-bottom: .7rem;
}

</style>